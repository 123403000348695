@import '@/assets/scss/variables.scss';
@import '@/assets/scss/mixin.scss';

.header {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 10px;
  width: 100%;
  border-bottom: 2px solid $default-background-color;
  background-color: #fefcfe;
  padding-bottom: 10px;
  z-index: 9990;

  @include respond-to(1440px) {
    justify-content: space-around;
  }

  @include respond-to(720px) {
    position: fixed;
    top: 0;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 10px;
}

.enter {
  @include defaultStyleButton(10px, 24px, 10px, 24px);
  @include mediumText(14px, 18px);
  max-height: 40px;
  background-color: $default-color-button;
  color: $default-text-white-color;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  border: none;
  border-radius: 50px;
  cursor: pointer;

  & img {
    margin-right: 10px;
  }

  @include respond-to(768px) {
    display: none;
  }
}

.plug {
  @include defaultStyleButton(10px, 24px, 10px, 24px);
  @include mediumText(16px, 20px);
  background-color: #ffc700;
  color: $default-text-black-color;

  @include respond-to(768px) {
    display: none;
  }
}

.header_wrapper {
  display: flex;
  align-items: center;

  @include respond-to(768px) {
    display: none;
  }
}

.registration {
  display: flex;
  justify-content: center;
  flex-grow: 2;
}

.logo {
  height: 40px;

  @include respond-to(720px) {
    width: 116px;
    height: 32px;
  }
}

.wrapper_logo {
  display: flex;
  justify-content: center;
  margin-right: 50px;
  cursor: pointer;

  @include respond-to(768px) {
    margin: 0;
  }
}

.burgerMenu {
  display: flex;
  align-items: center;
  width: 59px;
}

.wrapper_burgerMenu {
  background-color: transparent;
  padding: 0;
  border: none;
}
